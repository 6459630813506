<template>
  <div>
    <headerTop @shopTab="shopTabs"></headerTop>
    <headerMin></headerMin>
    <div class="content wrap">
      <h1>{{Data.shop_name}} ( {{Data.address}} )</h1>
      <div class="datas">
        <div class="fl">
          <img :src="Data.img" alt="">
          <!-- <img src="@/assets/image/bg1.jpg" alt /> -->
        </div>
        <div class="fr">
          <p>
            <img src="@/assets/image/address_Telephone_icon@2x.png" alt />
            电话:{{Data.mobile}}
          </p>
          <p>
            <img src="@/assets/image/address_address_icon@2x.png" alt />
            地址:{{Data.address}}
          </p>
          <div class="address" id="container1" tabindex="0"></div>
          <p @click="state = 1" class="see">查看地图</p>
        </div>
      </div>
      <div @click="state =0" v-show="state == 1" class="box">
        <div @click.stop class="boxs" id="container2" tabindex="0"></div>
      </div>
    </div>
    <publicBottom></publicBottom>
  </div>
</template>
<script>
import AMap from "AMap"; // 引入高德地图
import headerTop from "@/components/public/public_headerTop.vue";
import headerMin from "@/components/index/index_header_min";
// import landChecking from "@/components/land-checking.vue";
import publicBottom from "@/components/public/public_bottom.vue";
import { get_shop_list } from "@/request/public"; //门店
export default {
  components: {
    headerTop,
    headerMin,
    publicBottom
  },
  data() {
    return {
      id: "",
      lng: "",
      lat: "",
      Data: "",
      state: 0
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.lng = localStorage.getItem("lzyyShopLng");
    this.lat = localStorage.getItem("lzyyShopLat");
    this.start();
  },
  methods: {
    shopTabs(ids) {
      this.id = ids;
      this.start();
    },
    Goindex() {
      localStorage.setItem("lzyyShopId", this.Data.id);
      this.$router.replace({ path: "/" });
    },
    start() {
		this.$axios.post("/api/shop/details",{
			id:this.id,
			token:this.$token
		}).then(res=>{
			if(res.data.code==1){
				this.Data=res.data.data;
				       var map1 = new AMap.Map("container1", {
				         resizeEnable: true,
				         center: [this.Data.lng, this.Data.lat],
				         zoom: 18
				       });
				       var map2 = new AMap.Map("container2", {
				         resizeEnable: true,
				         center: [this.Data.lng, this.Data.lat],
				         zoom: 18
				       });
				       AMap.plugin("AMap.ToolBar", function() {
				         var toolbar = new AMap.ToolBar();
				         map1.addControl(toolbar);
				         map2.addControl(toolbar);
				       });
			}
		
		});
     //  get_shop_list({
     //    lng: this.lng,
     //    lat: this.lat
     //  }).then(res => {
     //    let datas = res.data.data;
     //    for (let i = 0; i < datas.length; i++) {
     //      if (datas[i].id == this.id) {
     //        this.Data = datas[i];
			  // console.log( this.Data );
     //        var map1 = new AMap.Map("container1", {
     //          resizeEnable: true,
     //          center: [this.Data.lng, this.Data.lat],
     //          zoom: 18
     //        });
     //        var map2 = new AMap.Map("container2", {
     //          resizeEnable: true,
     //          center: [this.Data.lng, this.Data.lat],
     //          zoom: 18
     //        });
     //        AMap.plugin("AMap.ToolBar", function() {
     //          var toolbar = new AMap.ToolBar();
     //          map1.addControl(toolbar);
     //          map2.addControl(toolbar);
     //        });
     //      }
     //    }
     //  });
    }
  }
};
</script>

<style lang="less" scoped>
.datas {
  overflow: hidden;
  .fl {
    width: 748px;
    height: 424px;
    img {
      width: 748px;
      height: 424px;
    }
  }
  .fr {
    width: 442px;
    height: 424px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(204, 204, 204, 1);
    padding-top: 30px;
    p {
      font-size: 16px;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 20px;
    }
    position: relative;
  }
  .address {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 440px;
    height: 266px;
  }
  .see {
    position: absolute;
    right: 0;
    bottom: -20px;
    width: 180px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    background: rgba(56, 238, 193, 1);
    z-index: 2;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }
}
.box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9;
}
.boxs {
  margin: auto;
  margin-top: 200px;
  width: 800px;
  height: 400px;
  z-index: 9;
}
h1 {
  margin: 20px;
}
button {
  width: 484px;
  height: 64px;
  background: rgba(56, 238, 193, 1);
  font-size: 28px;
  display: block;
  margin: auto;
  margin-top: 88px;
  margin-bottom: 50px;
}
</style>